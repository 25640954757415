import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { useParams } from "react-router";
import DirList from "../../components/DirList";
import { FileItem } from "../../types/projectTypes";
import NotFoundError from "../errors/NotFoundError";

import LoadingScreen from "../../components/LoadingScreen";
import useProjects from "../../hooks/useProjects";
import { Editor } from "@monaco-editor/react";
import PSMDocEditor from "../../components/PSMDocEditor";
import {
  closeFile,
  setCurrentFile,
  setFilesContent,
  setOpenFiles,
} from "../../slices/appSlice";
import { HiCog, HiOutlineEye } from "react-icons/hi";
import TreeView from "../../components/TreeView";
import { useSnackbar } from "notistack";

import { Dialogs } from "../../components/Dialog";

import psmdocParser from "../../utils/psmdocParser";
import PSMDocPreview from "../../components/PSMDocPreview";
import psmdocPreview from "../../components/PSMDocPreview";

const viewStates = {
  error: "error",
  preview: "preview",
};
const ProjectDetail = () => {
  const { currentProject, currentFile, filesContent, openFiles } = useSelector(
    (state: any) => state.app,
  );
  const { id } = useParams();
  const { enqueueSnackbar } = useSnackbar();

  const [appViewState, setAppViewState] = useState({
    error: null as null | string,
    viewState: null as null | string,
  });
  const { getProjectDetail } = useProjects();
  const { t } = useTranslation();
  const initialized = useRef(false);
  const dispatch = useDispatch();

  useEffect(() => {
    const loadProjectDetails = async () => {
      try {
        if (!id) {
          setAppViewState((prev) => {
            return { ...prev, error: t("Project ID not provided") };
          });
          return;
        }
        try {
          var projectId = parseInt(id);
        } catch (e) {
          setAppViewState((prev) => {
            return { ...prev, error: t("invalidProjectId") };
          });
          return;
        }

        try {
          const projectDetail = await getProjectDetail(projectId);
          if (!projectDetail) {
            // Handle error
            setAppViewState((prev) => {
              return { ...prev, error: t("errorLoadingProjectDetails") };
            });
            return;
          }
        } catch (e) {
          console.error(e);
          setAppViewState((prev) => {
            return { ...prev, error: t("errorLoadingProjectDetails") };
          });
        }
      } catch (e) {
        console.error(e);
        setAppViewState((prev) => {
          return { ...prev, error: t("errorLoadingProjectDetails") };
        });
      }
    };

    if (!initialized.current) {
      initialized.current = true;
      loadProjectDetails();
    }
  }, []);

  if (appViewState.error) {
    return <NotFoundError message={appViewState.error} />;
  }

  if (!currentProject) {
    return <LoadingScreen message={t("loadingProjectDetails")} />;
  }
  console.log(
    openFiles,
    filesContent,
    currentFile,
    Object.keys(filesContent).includes(currentFile?.id.toString()),
  );
  return (
    <div className="flex gap-2 bg-gray-100 dark:bg-gray-800">
      {currentFile &&
        filesContent[currentFile.id] &&
        appViewState.viewState !== null && (
          <Dialogs
            currentDialog={appViewState.viewState}
            onClose={() =>
              setAppViewState((prev) => {
                return { ...prev, viewState: null };
              })
            }
            dialogs={[
              {
                id: "showPreview",
                title: "Preview",
                size: "7xl",
                body: (
                  // <PSMDocPreview return string html with styles and scripts we
                  // can use dangerouslySetInnerHTML to render the html
                  <iframe
                    style={{ height: "600px", width: "100%" }}
                    srcDoc={psmdocPreview({
                      content: filesContent[currentFile.id].content,
                      title: currentFile.name,
                    })}
                  ></iframe>
                ),
              },
            ]}
          />
        )}
      {/* DirList is aside */}
      <div className="w-1/6 bg-gray-100 p-0  dark:bg-gray-800 overflow-x-auto ">
        <DirList
          dirs={[
            {
              id: 0,
              name: currentProject.name,
              kind: "directory",
              children: (currentProject.files as FileItem[]) || [],
            },
          ]}
        />
      </div>
      <div className="flex w-5/6 flex-col">
        {/* <div className="text-2xl font-bold">
          {t("projectDetail")} {currentProject.name}
        </div> */}
        <div className="flex h-10 flex-row gap-2">
          {openFiles &&
            openFiles?.map((file: FileItem, index: number) => (
              <div
                key={index}
                className={`flex gap-2 ${currentFile?.id === file.id ? "bg-green-300 dark:bg-green-500" : ""} p-2 dark:bg-gray-800 dark:text-white`}
              >
                <span
                  className="z-50 cursor-pointer text-blue-500 hover:text-blue-700 dark:text-blue-500 dark:hover:text-blue-700"
                  onClick={() => {
                    setAppViewState((prev) => {
                      return { ...prev, viewState: "showPreview" };
                    });
                  }}
                >
                  <HiOutlineEye />
                </span>
                <div
                  key={index}
                  className={`cursor-pointer `}
                  onClick={() => {
                    dispatch(setCurrentFile(file));
                  }}
                >
                  {file.name}
                  {/* add close  */}
                </div>
                <span
                  className="z-50 ml-2 cursor-pointer text-red-500 hover:text-red-700 dark:text-red-500 dark:hover:text-red-700"
                  onClick={() => {
                    dispatch(closeFile(file.id));
                  }}
                >
                  <HiCog />
                </span>
              </div>
            ))}
        </div>
        {currentFile &&
        Object.keys(filesContent).includes(currentFile.id.toString()) ? (
          <PSMDocEditor
            file={currentFile}
            content={filesContent ? filesContent[currentFile?.id].content : ""}
            onChange={(value: string | undefined, event) => {
              console.log(event);
              if (filesContent[currentFile.id].content === value) return;

              dispatch(
                setFilesContent({
                  ...filesContent,
                  [currentFile.id]: {
                    origin: "local",
                    content: value as string,
                  },
                }),
              );
            }}
          />
        ) : (
          <div>{t("noFileSelected")}</div>
        )}
      </div>
    </div>
  );
};

export default ProjectDetail;

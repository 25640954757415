import { Outlet, useNavigate } from "react-router";
import DashBoardHeader from "./dashboard_parts/DashBoardHeader";
import useAuth from "../hooks/useAuth";
import LoadingScreen from "../components/LoadingScreen";

const DashBoardLayout = ({children} : {children: any}) => {

  // flowbite css framework for styling
  return (
    <div className="bg-gray-100 dark:bg-gray-800">
      <DashBoardHeader />
      {children}
    </div>
  );
};

export default DashBoardLayout;

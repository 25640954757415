import React, { useEffect, useRef, useState } from "react";
import useProjects from "../../hooks/useProjects";
import { Project } from "../../types/projectTypes";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentProject, setProjects } from "../../slices/appSlice";
import { useTranslation } from "react-i18next";
import { useNavigation } from "react-router";
import { Link } from "react-router-dom";

const ProjectsIndex = () => {
  const { projects } = useSelector((state: any) => state.app);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [viewState, setViewState] = useState({
    projects: [] as Project[],
  });

  const { listProjects } = useProjects();
  const initialized = useRef(false);
  const navigation = useNavigation();

  useEffect(() => {
    const fetchData = async () => {
      const projects = await listProjects();
      if (projects) {
        setViewState((prev) => {
          return { ...prev, projects };
        });
        dispatch(setProjects(projects));
      }
    };
    if (!initialized.current) {
      initialized.current = true;

      fetchData();
    }
  }, [dispatch, listProjects]);
  return (
    //    use flowbite css framework for styling
    <div className="container mx-auto">
      <div className="flex h-screen items-center justify-center">
        <div className="w-96">
          <h1 className="text-center text-3xl font-bold">{t("projects")}</h1>

          {projects.length ? (
            // add wrapper div for project list
            <div className="mt-4" key="projects">
              <div className="mt-4 rounded bg-white p-4 shadow-md">
                <h2 className="text-xl font-bold">
                  {t("projects", { count: projects.length })}
                </h2>
              </div>

              {projects.map((project: any) => (
                <Link
                  key={project.id}
                  to={"/projects/" + project.id}
                  onClick={() => dispatch(setCurrentProject(project))}
                >
                  <ProjectListItem key={project.id} project={project} />
                </Link>
              ))}
            </div>
          ) : (
            <div className="mt-4 rounded bg-white p-4 shadow-md">
              <h2 className="text-xl font-bold">{t("projects.noProjects")}</h2>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const ProjectListItem = (props: { project: Project }) => {
  // return a card with project name, description and enable icon red or green
  return (
    <div className="mt-4 cursor-pointer rounded bg-white p-4 shadow-md">
      <h2 className="text-xl font-bold">{props.project.name}</h2>
      <p className="text-gray-500">{props.project.description}</p>
      <div className="mt-2 flex gap-2">
        {/* enable is a status flag icon */}
        <div
          className={`size-4 rounded-full ${
            props.project.enabled ? "bg-green-500" : "bg-red-500"
          }`}
        ></div>
      </div>
    </div>
  );
};

export default ProjectsIndex;

import { Card } from "flowbite-react";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Outlet } from "react-router";
import { Link } from "react-router-dom";
import { HiOutlineStatusOnline, HiPlusCircle } from "react-icons/hi";
import { Dialogs } from "../components/Dialog";
import FormBuilder from "../components/FormBuilder";
import useProjects from "../hooks/useProjects";

const viewStates = {
  addProject: "addProject",
};
const Home = () => {
  const { t } = useTranslation();
  const { projects } = useSelector((state: any) => state.app);
  const { addProject } = useProjects();
  const [appViewState, setAppViewState] = useState({
    error: null as null | string,
    viewState: null as null | string,
  });
  // use flowbite components and tailwindcss classes
  // add top navigation
  return (
    // show cards in a grid
    <div className="container grid grid-cols-1 gap-4 px-4 md:grid-cols-2 lg:grid-cols-3">
      {appViewState.viewState !== null && (
        <Dialogs
          currentDialog={appViewState.viewState}
          onClose={() => {
            setAppViewState((prev) => {
              return { ...prev, viewState: null };
            });
          }}
          dialogs={[
            {
              id: viewStates.addProject,
              title: t("addNewProject"),
              body: (
                <>
                  <FormBuilder
                    onSubmit={async (values) => {
                      console.log(values);
                      await addProject(values.name, values.description);
                      setAppViewState((prev) => {
                        return { ...prev, viewState: null };
                      });
                    }}
                    fields={[
                      {
                        label: t("projectName"),
                        type: "text",
                        name: "name",
                      },
                      {
                        label: t("projectDescription"),
                        type: "text",
                        name: "description",
                      },
                    ]}
                  />
                </>
              ),
            },
          ]}
        />
      )}
      {/* first card is for add new project  */}

      <Card
        className="my-4 border-gray-200 bg-white shadow-md dark:border-gray-700 dark:bg-gray-800 dark:text-white"
        renderImage={() => (
          // add dotted border, border radius,  div with plus icon in the center, place a wrapper to add padding before the square div
          // wrapper, div, icon
          <div className="min-h-30 flex w-full items-center justify-center p-4">
            <div className="flex h-20 w-20 items-center justify-center rounded-lg border-2 border-dashed">
              <HiPlusCircle size={40} />
            </div>
          </div>
        )}
        onClick={() => {
          // open add project dialog
          setAppViewState((prev) => {
            return { ...prev, viewState: viewStates.addProject };
          });
        }}
      >
        <h1 className="text-xl font-bold">{t("addNewProject")}</h1>
      </Card>

      {projects.map((project: any) => (
        <Link key={project.id} to={"/projects/" + project.id}>
          <Card
            key={project.id}
            className="my-4 border-gray-200 bg-white shadow-md dark:border-gray-700 dark:bg-gray-800 dark:text-white"
            renderImage={
              project.logo
                ? undefined
                : () => <HiOutlineStatusOnline size={30} />
            }
          >
            <h1 className="text-xl font-bold">{project.name}</h1>
            {project.enabled ? (
              <p className="text-green-500">{t("enabled")}</p>
            ) : (
              <p className="text-red-500">{t("disabled")}</p>
            )}
          </Card>
        </Link>
      ))}
    </div>
  );
};

export default Home;

import React, { useEffect } from "react";
import useAuth from "../../hooks/useAuth";
import { useNavigate } from "react-router";

const Login = () => {
  const [viewState, setViewState] = React.useState({
    email: "",
    code: "",
    codeRequested: false,
  });

  const { login, authenticate, isAuthenticated } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (isAuthenticated) {
      navigate("/");
    }
  }, [isAuthenticated]);
  // use flowbite css framework for styling
  return (
    <div className="container mx-auto">
      <div className="flex h-screen items-center justify-center">
        <div className="w-96">
          <h1 className="text-center text-3xl font-bold">Login</h1>
          <form className="mt-6">
            {!viewState.codeRequested ? (
              <div>
                <label className="block text-gray-700">Email</label>
                <input
                  type="email"
                  className="mt-2 w-full rounded-md border border-gray-300 p-3"
                  placeholder="Enter your email"
                  value={viewState.email}
                  onChange={(e) =>
                    setViewState({ ...viewState, email: e.target.value })
                  }
                />
                <button
                  onClick={async (e) => {
                    e.preventDefault();
                    await login(viewState.email);
                    setViewState({ ...viewState, codeRequested: true });
                  }}
                  className="mt-6 w-full rounded-md bg-blue-600 py-3 text-white hover:bg-blue-500"
                >
                  Request Access Code
                </button>
              </div>
            ) : (
              <div>
                <label className="block text-gray-700">Access Code</label>
                <input
                  type="text"
                  className="mt-2 w-full rounded-md border border-gray-300 p-3"
                  placeholder="Enter your access code"
                  value={viewState.code}
                  onChange={(e) =>
                    setViewState({ ...viewState, code: e.target.value })
                  }
                />
                <button
                  onClick={async (e) => {
                    e.preventDefault();
                    var resAuth = await authenticate(
                      viewState.email,
                      parseInt(viewState.code),
                    );
                    if (resAuth.error) {
                      alert(resAuth.error);
                    } 
                  }}
                  className="mt-6 w-full rounded-md bg-blue-600 py-3 text-white hover:bg-blue-500"
                >
                  Login
                </button>
              </div>
            )}
          </form>
        </div>
      </div>
    </div>
  );
};

export default Login;

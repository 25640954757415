const server = "https://psmdoc.prioritysupport.mobi";
const wsServer = "wss://psmdoc.prioritysupport.mobi";

const appName = "PSMDoc";
const localStorageVars = {
  storageName: "psmdoc",
  token: "tk",
};

export { server, localStorageVars, wsServer, appName };

import { useEffect, useState } from "react";

const useWebSocket = (url) => {
  const [messages, setMessages] = useState([]);
  const [ws, setWs] = useState(null);
  const [wsOpen, setWsOpen] = useState(false);
  const [reconnect, setReconnect] = useState({
    reconnecting: false,
    reconnectCount: 0,
    reconnectInterval: 1000,
  });

  const sendMessage = (message) => {
    if (wsOpen) {
      ws.send(JSON.stringify(message));
    }
  };
  const connect = () => {
    const ws = new WebSocket(url);
    setWs(ws);

    ws.onmessage = (event) => {
      console.log(event.data);
      setMessages((prevMessages) => [
        ...prevMessages,
        {
          ...JSON.parse(event.data).message,
          status: "received",
        },
      ]);
    };
    ws.onopen = () => {
      setWsOpen(true);
    };
    ws.onclose = (event) => {
      if (event.wasClean) {
        console.log("Connection closed cleanly");
      } else {
        console.log("Connection closed unexpectedly");
        setReconnect((prevReconnect) => ({
          ...prevReconnect,
          reconnecting: true,
          reconnectCount: prevReconnect.reconnectCount + 1,
        }));
        setTimeout(() => {
          connect();
        }, reconnect.reconnectInterval * reconnect.reconnectCount);
      }
      setWsOpen(false);
      // implement reconnect using standard backoff algorithm
    };

    return () => {
      ws.close();
    };
  };

  useEffect(() => {
    connect();
    // const ws = new WebSocket(url);
    // setWs(ws);

    // ws.onmessage = (event) => {
    //   console.log(event.data);
    //   setMessages((prevMessages) => [
    //     ...prevMessages,
    //     {
    //       ...JSON.parse(event.data).message,
    //       status: "received",
    //     },
    //   ]);
    // };
    // ws.onopen = () => {
    //   setWsOpen(true);
    // };
    // ws.onclose = () => {
    //   setWsOpen(false);
    //   // implement reconnect using standard backoff algorithm

    // };

    // return () => {
    //   ws.close();
    // };
  }, [url]);

  const removeMessage = (id) => {
    setMessages((prevMessages) =>
      prevMessages.filter((message) => message.id !== id),
    );
  };

  const clearMessages = (messagesIds) => {
    if (messagesIds.length) {
      setMessages((prevMessages) =>
        prevMessages.filter((message) => !messagesIds.includes(message.id)),
      );
    } else {
      setMessages([]);
    }
  };

  return { messages, ws, sendMessage, removeMessage, clearMessages, wsOpen };
};

export default useWebSocket;

import axios from "../utils/axiosInstance";
import { server, wsServer } from "../config";
import { ConvertProject, FileItem, Project } from "../types/projectTypes";
import { useDispatch, useSelector } from "react-redux";
import { setAppStateItems, setCurrentProject, setFilesContent, setProjects } from "../slices/appSlice";
import useWebSocket from "./useWebScoket";
import { useEffect, useRef } from "react";
import useAuth from "./useAuth";

type Timeouts = {
  [key: string]: NodeJS.Timeout;
};

const useProjects = () => {
  const dispatch = useDispatch();

  const { currentProject, filesContent } = useSelector(
    (state: any) => state.app,
  );

  const listProjects = async () => {
    try {
      const res = await axios.post(`${server}/api/project/list/`);

      //    return list of projects from the response
      var projects = [] as Project[];
      res.data.data.forEach((element: any) => {
        projects.push(ConvertProject.fromJson(element)); // Access the static member "fromJson" of the "ConvertProject" class
      });
      dispatch(setProjects(projects));
      return projects;
    } catch (err) {
      console.error(err);
    }
  };

  const addProject = async (name: string, description: string) => {
    try {
      const res = await axios.post(`${server}/api/project/create/`, {
        name: name,
        description: description,
      });
      listProjects();
      return res.data.data;
    } catch (err) {
      console.error(err);
    }
  };

  const addProjectFile = async (file: FileItem) => {
    try {
      const res = await axios.post(`${server}/api/project/files/create/`, {
        project_id: currentProject.id,
        name: `${file.path}/${file.name}`,
        kind: file.kind,
      });

      getProjectFiles(currentProject.id);
      return res.data.data;
    } catch (err) {
      console.error(err);
    }
  };
  const getProjectFiles = async (projectId: number) => {
    try {
      const res = await axios.post(`${server}/api/project/files/`, {
        project_id: projectId,
      });

      dispatch(
        setCurrentProject({
          ...currentProject,
          files: res.data.data,
        }),
      );
      return res.data.data;
    } catch (err) {
      console.error(err);
    }
  };

  const getProjectDetail = async (projectId: number) => {
    try {
      const res = await axios.post(`${server}/api/project/get/`, {
        id: projectId,
        project_id: projectId,
      });

      dispatch(setCurrentProject(ConvertProject.fromJson(res.data.data)));

      return res.data.data;
    } catch (err) {
      console.error(err);
    }
  };

  return {
    listProjects,
    getProjectFiles,
    addProjectFile,
    getProjectDetail,
    addProject,
  };
};

const useProjectsWs = () => {
  const { currentProject, filesContent, currentFile,  } = useSelector(
    (state: any) => state.app,
  );
  const { ws, sendMessage, messages, removeMessage, clearMessages, wsOpen } =
    useWebSocket(`${wsServer}/ws/project/${currentProject.projectKey}/`);

  const timeouts = useRef<Timeouts>({} as Timeouts);

  const { user } = useAuth();
  const dispatch = useDispatch();

  const subscribeToProject = () => {
    sendMessage({ action: "subscribe" });
  };
  const unsubscribeToProject = () => {
    sendMessage({ action: "unsubscribe" });
  };

  const getFileContent = (file: FileItem) => {
    sendMessage({ action: "get_file", file: file.name });
  };

  const debounceSendFileContent = (fileId: string, content: string) => {
    if (timeouts && timeouts.current[fileId]) {
      clearTimeout(timeouts.current[fileId]);
    }
    var _timeouts = timeouts.current;
    _timeouts[fileId] = setTimeout(() => {
      sendMessage({
        type: "update_file_content",
        message: {
          type: "update_file_content",
          file_id: fileId,
          content: content,
        },
      });
    }, 500);
    timeouts.current = _timeouts;
  };

  useEffect(() => {
    if (!currentProject.id || !user || !filesContent) return;
    var processedMessages = [] as any;
    var _filesContent: any = { ...filesContent };
    var changed = false;

    if (messages) {
      messages.forEach((message: any) => {
        var msg = message;
        var msgType = msg.type;
        switch (msgType) {
          case "file_content":
            var fileId = msg.message.file_id;
            var userId = msg.user_id;
            var content = msg.message.content;

            if (
              !_filesContent[fileId] ||
              _filesContent[fileId].content !== content
            ) {
              _filesContent[fileId] = {
                content: content,
                origin: "remote",
              };
              changed = true;
            }
            processedMessages.push(msg.id);
            break;
          case "file_content_updated":
            if (msg.user_id !== user.id) {
              var fileId = msg.message.file_id;
              var content = msg.message.content;

              if (_filesContent[fileId]?.content !== content) {
                _filesContent[fileId] = {
                  content: content,
                  origin: "remote",
                };
                changed = true;
              }
            }
            processedMessages.push(msg.id);
            break;
          default:
            break;
        }
      });
    }
    if (changed) {
      dispatch(setFilesContent(_filesContent));
    }
    if (processedMessages.length > 0) {
      clearMessages(processedMessages);
    }
  }, [messages]);

  useEffect(() => {
    if (currentFile && currentFile.id && !filesContent[currentFile.id]) {
      sendMessage({
        type: "get_file_content",
        message: {
          type: "get_file_content",
          file_id: currentFile.id,
        },
      });
    } else if (currentFile && currentFile.id && filesContent[currentFile.id]) {
      if (filesContent[currentFile.id].origin === "local") {
        debounceSendFileContent(
          currentFile.id,
          filesContent[currentFile.id].content,
        );

        // sendMessage({
        //   type: "update_file_content",
        //   message: {
        //     type: "update_file_content",
        //     file_id: currentFile.id,
        //     content: filesContent[currentFile.id].content,
        //   },
        // });
      }
    }
  }, [currentFile, filesContent[currentFile?.id]]);

  useEffect(() => {
    dispatch(setAppStateItems({projectWsOpen: wsOpen}));
  }, [wsOpen]);

  return {
    subscribeToProject,
    unsubscribeToProject,
    getFileContent,
    ws,
    messages,
    sendMessage,
    removeMessage,
    clearMessages,
    projectWsOpen: wsOpen,
  };
};

export { useProjectsWs };

export default useProjects;

import React from "react";

import psmdocParser from "../utils/psmdocParser";

const psmdocPreview = ({
  content,
  title,
}: {
  content: string;
  title: string;
}) => {
  return `
        <!DOCTYPE html>
  <html lang="en">
  <head>
    <meta charset="UTF-8">
    <meta http-equiv="X-UA-Compatible" content="IE=edge">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>${title} | ${title}</title>
    <meta name="description" content="">
    <meta name="keywords" content="">
    <meta name="author" content="">
    <meta name="robots" content="index, follow">    
    <link rel="stylesheet" href="https://psmdoc.prioritysupport.mobi/djstatic/css/psmdoc.css">
    </style>
  </head>
    <body>
      <main>       
        <div class="psm-mobile-menu" id="psm-mobile-menu">
          <div class="psm-mobile-menu-button">
            ☰ ${title} 
              
          </div>
          <div class="psm-mobile-menu-content">
            <ul>
                <li><a href="#top">Top</a></li>
                <li><a href="#content">Content</a></li>
                <li><a href="#footer">Footer</a></li>
            </ul>
          </div>
        </div>
        <header>
          <div class="psm-row">
            <!-- header -->
          </div>
        </header>
        <div class="psm-row">          
          <div class="psm-content">           
            
            ${psmdocParser(content)}
          </div>
          <div class="psm-sidebar">
            <h2>Menu</h2>
            <ul>
                <li><a href="#top">Top</a></li>
                <li><a href="#content">Content</a></li>
                <li><a href="#footer">Footer</a></li>
            </ul>
          </div>
        </div>
        <div class="psm-row">
          <!-- footer -->     
        </div>
      </main>
      <footer>
        <p>&copy; ${title} ${new Date().getFullYear()}</p>
      </footer>
    </body>
    <script src="https://psmdoc.prioritysupport.mobi/djstatic/js/psmdoc.js"></script>
  </html>`;
};

export default psmdocPreview;

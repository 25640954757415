import { useDispatch, useSelector } from "react-redux";
import useProjects, { useProjectsWs } from "../../hooks/useProjects";
import { useEffect, useRef, useState } from "react";
import { setFiles, setFilesContent, setProjects } from "../../slices/appSlice";
import useAuth from "../../hooks/useAuth";
import { Outlet, useNavigate, useNavigation } from "react-router";
import DashBoardLayout from "../../layouts/DashBoardLayout";
import { Project } from "../../types/projectTypes";
import { useTranslation } from "react-i18next";
import LoadingScreen from "../../components/LoadingScreen";

const ProjectsWsWrapper = ({
  children,
  currentProject,
}: {
  children: any;
  currentProject: any;
}) => {
  const { currentFile, openFiles, filesContent } = useSelector(
    (state: any) => state.app,
  );
  const { messages, sendMessage, removeMessage, clearMessages, projectWsOpen } =
    useProjectsWs();
  // const dispatch = useDispatch();
  const { user } = useAuth();

  return <div>{children}</div>;
};

const ProjectsLayout = () => {
  const { currentProject } = useSelector((state: any) => state.app);
  const { projects } = useSelector((state: any) => state.app);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [viewState, setViewState] = useState({
    projects: [] as Project[],
  });
  const { getProfile, authLoaded, isAuthenticated, isLoadingProfile } =
    useAuth();
  const navigate = useNavigate();

  if (!isAuthenticated && authLoaded) {
    navigate("/login");
  }

  const { listProjects } = useProjects();
  const initialized = useRef(false);
  const navigation = useNavigation();

  useEffect(() => {
    if (!authLoaded && !isAuthenticated && !isLoadingProfile) {
      // get profile load user from local storage and set isAuthenticated to true
      // if getProfile is successful
      getProfile();
    }
    if (authLoaded && !isAuthenticated) {
      navigate("/login");
    }
  }, [isAuthenticated, authLoaded, getProfile]);

  useEffect(() => {
    if (!isAuthenticated) return;
    const fetchData = async () => {
      const projects = await listProjects();
      // if (projects) {
      //   setViewState((prev) => {
      //     return { ...prev, projects };
      //   });
      //   dispatch(setProjects(projects));
      // }
    };
    if (!initialized.current) {
      initialized.current = true;

      fetchData();
    }
  }, [isAuthenticated, dispatch, listProjects]);

  if (!authLoaded) return <LoadingScreen message="Loading Auth" />;

  if (currentProject && currentProject.projectKey) {
    return (
      <DashBoardLayout>
        <ProjectsWsWrapper currentProject={currentProject}>
          <Outlet />
        </ProjectsWsWrapper>
      </DashBoardLayout>
    );
  }
  return <DashBoardLayout>{<Outlet />}</DashBoardLayout>;
};

export default ProjectsLayout;

import {
  Button,
  Label,
  Radio,
  Select,
  TextInput,
  Textarea,
  ToggleSwitch,
} from "flowbite-react";
import React, { FC, SVGProps } from "react";
import { HiOutlineX, HiOutlineCheck } from "react-icons/hi";

type FormFieldOption = {
  label: string;
  value: string;
};

enum FormFieldType {
  TEXT = "text",
  PASSWORD = "password",
  EMAIL = "email",
  NUMBER = "number",
  SELECT = "select",
  CHECKBOX = "checkbox",
  RADIO = "radio",
  SWITCH = "switch",
  TEXTAREA = "textarea",
  DATE = "date",
  TIME = "time",
  DATETIME = "datetime",
  FILE = "file",
  HIDDEN = "hidden",
  COLOR = "color",
  RANGE = "range",
  URL = "url",
  TEL = "tel",
  SEARCH = "search",
  MONTH = "month",
  WEEK = "week",
  DATETIME_LOCAL = "datetime-local",
  CURRENCY = "currency",
  PERCENTAGE = "percentage",
  PASSWORD_WITH_CONFIRMATION = "password_with_confirmation",
}

type FormField = {
  label: string;
  type: string;
  name: string;
  icon?: FC<SVGProps<SVGSVGElement>> | undefined;
  optional?: boolean;
  options?: FormFieldOption[];
  color?: string;
  placeholder?: string;
  helpText?: string;
  initialValue?: string;
  disabled?: boolean;
  hidden?: boolean;
  readOnly?: boolean;
  className?: string;
  style?: React.CSSProperties;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void;
  onClick?: (event: React.MouseEvent<HTMLInputElement>) => void;
  validation?: (value: string, values: {}) => string | null;
};

type FormFieldGroup = {
  label: string;
  fields: FormField[];
};

type FormFieldRow = {
  label?: string;
  fields: FormField[];
};

type FormFieldProps = {
  id?: string;
  fields: FormField[] | FormFieldGroup[] | FormFieldRow[];
  className?: string;
  style?: React.CSSProperties;
  onChange?: (values: {}) => void;
  onCancel?: () => void;
  onSubmit?: (values: {[key: string]: any}) => void;
  initialValues?: { [key: string]: any } | null;
  errors?: {
    [key: string]: string;
  };
  disabled?: boolean;
  readOnly?: boolean;
  hidden?: boolean;
  buttonLabels?: {
    submit?: string;
    cancel?: string;
  };
  renderButtons?: (props: {
    submit: () => void;
    cancel: () => void;
  }) => React.ReactNode;
};

const FormBuilder = (props: FormFieldProps) => {
  const {
    fields,
    className,
    style,
    onChange,
    onCancel,
    onSubmit,
    initialValues,
    errors,
    disabled,
    readOnly,
    hidden,
  } = props;
  const [values, setValues] = React.useState(
    props.initialValues || ({} as { [key: string]: any }),
  );
  const [formErrors, setFormErrors] = React.useState(
    errors || ({} as { [key: string]: string }),
  );
  const initialized = React.useRef(false);
  const [formState, setFormState] = React.useState({
    initialized: false,
  });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>, field: FormField) => {
    const { name, value } = event.target;

    if (onChange) {
      onChange({ ...values, [name]: value });
    }

    setValues((prevValues) => {
      return { ...prevValues, [name]: value };
    });
    handleValidation(field);
  };

  const handleCancel = () => {
    if (onCancel) {
      onCancel();
    }
  };

  const handleValidation = (field: FormField) => {
    const { name, validation } = field;
    if (!validation) {
      //   perform basic validation for each field
      // is not empty and is not null
      var value = values[name];
      if (
        !field.optional &&
        (!value || value === "" || value === null || value === undefined)
      ) {
        return `${field.label} is required`;
      }
      if (field.type === FormFieldType.EMAIL) {
        if (!value.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)) {
          setFormErrors({ ...formErrors, [name]: "Invalid email" });
          return "Invalid email";
        }
      }
      if (field.type === FormFieldType.NUMBER) {
        if (isNaN(Number(value))) {
          setFormErrors({ ...formErrors, [name]: "Invalid number" });
          return "Invalid number";
        }
      }

      if (field.type === FormFieldType.PASSWORD_WITH_CONFIRMATION) {
        if (values[name] !== values[`${name}_confirmation`]) {
          setFormErrors({ ...formErrors, [name]: "Passwords do not match" });
          return "Passwords do not match";
        }
      }

      if (field.type === FormFieldType.PERCENTAGE) {
        if (isNaN(Number(value)) || Number(value) < 0 || Number(value) > 100) {
          setFormErrors({ ...formErrors, [name]: "Invalid percentage" });
          return "Invalid percentage";
        }
      }

      if (field.type === FormFieldType.CURRENCY) {
        if (isNaN(Number(value))) {
          setFormErrors({ ...formErrors, [name]: "Invalid currency" });
          return "Invalid currency";
        }
      }

      if (field.type === FormFieldType.URL) {
        if (!value.match(/^(http|https):\/\/[^ "]+$/)) {
          setFormErrors({ ...formErrors, [name]: "Invalid URL" });
          return "Invalid URL";
        }
      }

      if (field.type === FormFieldType.TEL) {
        if (!value.match(/^\d{10}$/)) {
          setFormErrors({ ...formErrors, [name]: "Invalid phone number" });
          return "Invalid phone number";
        }
      }

      if (field.type === FormFieldType.DATE) {
        if (!value.match(/^\d{4}-\d{2}-\d{2}$/)) {
          setFormErrors({ ...formErrors, [name]: "Invalid date" });
          return "Invalid date";
        }
      }

      if (field.type === FormFieldType.TIME) {
        if (!value.match(/^\d{2}:\d{2}$/)) {
          setFormErrors({ ...formErrors, [name]: "Invalid time" });
          return "Invalid time";
        }
      }

      if (field.type === FormFieldType.DATETIME) {
        if (!value.match(/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}$/)) {
          setFormErrors({ ...formErrors, [name]: "Invalid datetime" });
          return "Invalid datetime";
        }
      }

      if (field.type === FormFieldType.MONTH) {
        if (!value.match(/^\d{4}-\d{2}$/)) {
          setFormErrors({ ...formErrors, [name]: "Invalid month" });
          return "Invalid month";
        }
      }

      if (field.type === FormFieldType.WEEK) {
        if (!value.match(/^\d{4}-W\d{2}$/)) {
          setFormErrors({ ...formErrors, [name]: "Invalid week" });
          return "Invalid week";
        }
      }

      if (field.type === FormFieldType.DATETIME_LOCAL) {
        if (!value.match(/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}$/)) {
          setFormErrors({ ...formErrors, [name]: "Invalid datetime-local" });
          return "Invalid datetime-local";
        }
      }

      if (field.type === FormFieldType.COLOR) {
        if (!value.match(/^#[0-9A-F]{6}$/)) {
          setFormErrors({ ...formErrors, [name]: "Invalid color" });
          return "Invalid color";
        }
      }

      if (field.type === FormFieldType.RANGE) {
        if (isNaN(Number(value))) {
          setFormErrors({ ...formErrors, [name]: "Invalid range" });
          return "Invalid range";
        }
      }

      if (field.type === FormFieldType.FILE) {
        if (!value.match(/^[^\s]+$/)) {
          setFormErrors({ ...formErrors, [name]: "Invalid file" });
          return "Invalid file";
        }
      }

      if (field.type === FormFieldType.SEARCH) {
        if (!value.match(/^[^\s]+$/)) {
          setFormErrors({ ...formErrors, [name]: "Invalid search" });
          return "Invalid search";
        }
      }

      if (field.type === FormFieldType.TEXT) {
        if (!value.match(/^[^\s]+$/)) {
          setFormErrors({ ...formErrors, [name]: "Invalid text" });
          return "Invalid text";
        }
      }

      if (field.type === FormFieldType.PASSWORD) {
        if (!value.match(/^[^\s]+$/)) {
          setFormErrors({ ...formErrors, [name]: "Invalid password" });
          return "Invalid password";
        }
      }

      if (field.type === FormFieldType.HIDDEN) {
        if (!value.match(/^[^\s]+$/)) {
          setFormErrors({ ...formErrors, [name]: "Invalid hidden value" });
          return "Invalid hidden";
        }
      }

      if (field.type === FormFieldType.CHECKBOX) {
        // value should be one of the options
        if (!field.options?.map((option) => option.value).includes(value)) {
          setFormErrors({ ...formErrors, [name]: "Invalid checkbox value" });
          return "Invalid checkbox";
        }
      }

      if (field.type === FormFieldType.RADIO) {
        // value should be one of the options
        if (!field.options?.map((option) => option.value).includes(value)) {
          setFormErrors({ ...formErrors, [name]: "Invalid radio value" });
          return "Invalid radio";
        }
      }

      if (field.type === FormFieldType.SWITCH) {
        if (value !== "true" && value !== "false") {
          setFormErrors({ ...formErrors, [name]: "Invalid switch value" });
          return "Invalid switch";
        }
      }

      if (field.type === FormFieldType.SELECT) {
        // value should be one of the options
        if (!field.options?.map((option) => option.value).includes(value)) {
          setFormErrors({ ...formErrors, [name]: "Invalid select value" });
          return "Invalid option selected";
        }
      }

      //   remove name from errors
      if (formErrors[name]) {
        var _formErrors = { ...formErrors };
        delete _formErrors[name];
        setFormErrors(_formErrors);
      }
      return null;
    }
    return validation(values[name], values);
  };

  React.useEffect(() => {
    if (!initialized.current) {
      initialized.current = true;
      var _fieldsInitialValues: { [key: string]: any } = {}; // Add type annotation for _fieldsInitialValues
      fields.forEach((field) => {
        if ("fields" in field) {
          field.fields.forEach((f) => {
            _fieldsInitialValues[f.name] = f.initialValue || "";
          });
        } else {
          _fieldsInitialValues[field.name] = field.initialValue || "";
        }
      });
      // check if initialValues is not null
      // if any in initialValues is not in fields, add it to fields
      if (initialValues) {
        Object.keys(initialValues).forEach((key) => {
          if (!_fieldsInitialValues[key] && initialValues[key]) {
            _fieldsInitialValues[key] = initialValues[key];
          }
        });
      }
      setValues(_fieldsInitialValues);
      setFormState({ initialized: true });
    }
  }, [initialValues]);

  const renderField = (field: FormField) => {
    const {
      label,
      type,
      name,
      icon,
      optional,
      options,
      color,
      placeholder,
      helpText,
      initialValue,
      disabled,
      hidden,
      readOnly,
      className,
      style,
      validation,
    } = field;
    const value = values ? values[name] : initialValue || "";
    const error = formErrors[name] || "";

    if (hidden) {
      return null;
    }
    const commonClasses = "flex max-w-md flex-col gap-1";

    switch (type) {
      case FormFieldType.TEXT:
      case FormFieldType.PASSWORD:
      case FormFieldType.EMAIL:
      case FormFieldType.NUMBER:
      case FormFieldType.DATE:
      case FormFieldType.TIME:
      case FormFieldType.DATETIME:
      case FormFieldType.FILE:
      case FormFieldType.HIDDEN:
      case FormFieldType.COLOR:
      case FormFieldType.RANGE:
      case FormFieldType.URL:
      case FormFieldType.TEL:
      case FormFieldType.SEARCH:
      case FormFieldType.MONTH:
      case FormFieldType.WEEK:
      case FormFieldType.DATETIME_LOCAL:
      case FormFieldType.CURRENCY:
      case FormFieldType.PERCENTAGE:
        return (
          <div
            className={`${commonClasses}`}
            id={`form-field-${name}`}
            key={name}
          >
            <div className="mb-2 block">
              <Label htmlFor={name}>{label}</Label>
            </div>
            <TextInput
              type={type}
              name={name}
              value={value}
              required={!optional}
              icon={icon}
              placeholder={placeholder}
              disabled={disabled}
              readOnly={readOnly}
              className={`mt-1  ${className}`}
              style={style}
              onChange={(event) => {
                
                handleChange(event as any, field);
              }}
            />
            {error && <p className="text-sm text-red-500">{error}</p>}
            {helpText && <p className="text-sm text-gray-500">{helpText}</p>}
          </div>
        );
      case FormFieldType.SELECT:
        return (
          <div
            className={`${commonClasses}`}
            id={`form-field-${name}`}
            key={name}
          >
            <Label htmlFor={name}>{label}</Label>
            <Select
              name={name}
              value={value}
              onChange={(event) => handleChange(event as any, field)}
            >
              {options &&
                options.map((option, index) => (
                  <option key={index} value={option.value}>
                    {option.label}
                  </option>
                ))}
            </Select>
            {error && <p className="text-sm text-red-500">{error}</p>}
            {helpText && <p className="text-sm text-gray-500">{helpText}</p>}
          </div>
        );

      case FormFieldType.CHECKBOX:
      case FormFieldType.RADIO:
        return (
          <fieldset
            className={`${commonClasses}`}
            id={`form-field-${name}`}
            key={name}
          >
            <legend className="text-sm text-gray-600">{label}</legend>
            {options &&
              options.map((option, index) => (
                <div
                  className="flex items-center gap-2"
                  key={`${name}-${index}`}
                >
                  <Radio
                    id={`${name}-${index}`}
                    name={name}
                    value={option.value}
                    checked={value === option.value}
                    onChange={(e) => handleChange(e as any, field)}
                  />
                  <Label htmlFor={`${name}-${index}`}>{option.label}</Label>
                </div>
              ))}
            {error && <p className="text-sm text-red-500">{error}</p>}
            {helpText && <p className="text-sm text-gray-500">{helpText}</p>}
          </fieldset>
        );

      case FormFieldType.SWITCH:
        return (
          <div
            className={`${commonClasses}`}
            id={`form-field-${name}`}
            key={name}
          >
            <ToggleSwitch
              label={label}
              name={name}
              checked={value}
              onChange={(event) => handleChange(event as any, field)}
            />
            {error && <p className="text-sm text-red-500">{error}</p>}
            {helpText && <p className="text-sm text-gray-500">{helpText}</p>}
          </div>
        );
      case FormFieldType.TEXTAREA:
        return (
          <div
            className={`${commonClasses}`}
            id={`form-field-${name}`}
            key={name}
          >
            <Label htmlFor={name}>{label}</Label>
            <Textarea
              name={name}
              value={value}
              required={!optional}
              placeholder={placeholder}
              disabled={disabled}
              readOnly={readOnly}
              className={`${className}`}
              style={style}
              onChange={(event) => handleChange(event as any, field)}
            />
            {error && <p className="text-sm text-red-500">{error}</p>}
            {helpText && <p className="text-sm text-gray-500">{helpText}</p>}
          </div>
        );
      default:
        console.error(`Unknown field type: ${type}`);
        return null;
    }
  };

  if (!formState.initialized) {
    return null;
  }

  return (
    <form
      className={`flex flex-col gap-1 ${className}`}
      style={style}
      onSubmit={(event) => {
        event.preventDefault();
        if (onSubmit) {

          onSubmit(values);
        }
      }}
    >
      {fields.map((field, index) => {
        if ("fields" in field) {
          return (
            <div key={index} className="flex flex-col gap-2">
              {field.label && (
                <div className="text-lg font-bold">{field.label}</div>
              )}

              <div className="flex flex-col gap-4">
                {field.fields.map((f, i) => (
                  <div key={i} className="flex flex-col gap-4">
                    {renderField(f)}
                  </div>
                ))}
              </div>
            </div>
          );
        }

        return renderField(field);
      })}
      <div className="mt-4 flex items-center gap-4">
        {props.renderButtons ? (
          props.renderButtons({
            submit: () => {
              if (onSubmit) {
                onSubmit(values);
              }
            },
            cancel: () => {
              if (onCancel) {
                onCancel();
              }
            },
          })
        ) : (
          <>
            <div className="flex-1">
              <Button type="button" onClick={handleCancel} color="failure">
                <HiOutlineX className="mr-2 h-5 w-5" />
                {props.buttonLabels?.cancel || "Cancel"}
              </Button>
            </div>
            <div className="flex flex-1 justify-end">
              <Button
                type="submit"
                disabled={Object.keys(formErrors).length !== 0}
                color="success"
              >
                <HiOutlineCheck className="mr-2 h-5 w-5" />
                {props.buttonLabels?.submit || "Submit"}
              </Button>
            </div>
          </>
        )}
      </div>
    </form>
  );
};

export { FormBuilder,  FormFieldType };

export default FormBuilder;

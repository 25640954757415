import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "./i18n";

import reportWebVitals from "./reportWebVitals";
import store from "./app/store";
import { Provider } from "react-redux";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import Home from "./views/Home";
import NotFoundError from "./views/errors/NotFoundError";
import ProjectsIndex from "./views/projects/ProjectsIndex";
import DashBoardLayout from "./layouts/DashBoardLayout";
import Login from "./views/auth/Login";
import ProjectDetail from "./views/projects/ProjectDetail";
import ProjectsLayout from "./views/projects/ProjectsLayout";
import Logout from "./views/auth/Logout";
import { SnackbarProvider } from "notistack";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);
const router = createBrowserRouter([
  {
    path: "/",
    element: <ProjectsLayout />,
    errorElement: <NotFoundError />,
    children: [
      { path: "/", element: <Home />, index: true },
      {
        path: "/projects/:id",
        element: <ProjectDetail />,
      },
      {
        path: "/projects/",
        element: <ProjectsIndex />,
      },
      {
        path: "/logout",
        element: <Logout />,
      },
    ],
  },
  {
    path: "/login",
    element: <Login />,
  },
]);
root.render(
  <Provider store={store}>
    <SnackbarProvider>
      <React.StrictMode>
        <RouterProvider router={router} />
      </React.StrictMode>
    </SnackbarProvider>
  </Provider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

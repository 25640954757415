import React from "react";

const LoadingScreen = ({ message }: { message: string }) => {
  // use flowbite css framework for styling
  return (
    <div className="fixed left-0 top-0 flex size-full items-center justify-center bg-gray-900/50">
      <div className="rounded-lg bg-white p-4 shadow-lg">
        <h1 className="text-xl font-bold">Loading {message}...</h1>
      </div>
    </div>
  );
};

export default LoadingScreen;

import axios from "axios";
import { localStorageVars, server } from "../config";

const axiosInstance = axios.create();
axiosInstance.defaults.withCredentials = true;
axiosInstance.defaults.xsrfCookieName = "csrftoken";
axiosInstance.defaults.xsrfHeaderName = "X-CSRFTOKEN";
axiosInstance.defaults.baseURL = server;
axiosInstance.interceptors.request.use((config) => {
  const accessToken = localStorage.getItem(localStorageVars.token);
  if (accessToken) {
    axiosInstance.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  }
  config.headers.Authorization = accessToken ? `Bearer ${accessToken}` : "";
  return config;
});

export default axiosInstance;
export { axiosInstance as axios };

import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentFile, setOpenFiles } from "../slices/appSlice";
// Show directory and files navigation as window explorer
// we aer using flowbite css framework for styling
import { FileItem } from "../types/projectTypes";
import { useMonaco } from "@monaco-editor/react";
import { Dialogs } from "./Dialog";
import { useTranslation } from "react-i18next";
import { Button, Label, Select, TextInput, Tooltip } from "flowbite-react";
import useProjects from "../hooks/useProjects";
import MenuContext from "./MenuContext";
import {
  HiFolderAdd,
  HiDocumentAdd,
  HiRefresh,
  HiChevronDown,
  HiChevronRight,
} from "react-icons/hi";

const DirList = ({
  dirs,
  hasParent,
}: {
  dirs: FileItem[];
  hasParent?: boolean;
}) => {
  const { openFiles } = useSelector((state: any) => state.app);
  const dispatch = useDispatch();
  const initialStates = {
    newFile: false,
    newFolder: false,
    uploadFile: false,
    syncFiles: false,
    currentDialog: "",
  };
  const [dirListState, setDirListState] = React.useState({
    expandedFolders: [] as string[],
    ...initialStates,
  });
  const { t } = useTranslation();
  const { addProjectFile } = useProjects();
  // const monaco = useMonaco();

  React.useEffect(() => {
    // const addPsmDocLanguageToMonaco = () => {
    //   if (!monaco) return;
    //   const keywords = [
    //     /^#H[1-6].\s/,
    //     /^#TABLE.\s/,
    //     /#.ACCORDION.\s/,
    //     /#EL.\s/,
    //     /#CODE.\s/,
    //     /#ENDCODE/,
    //     /#VIDEO.\s/,
    //     /#IMG.\s/,
    //     /#.\s/,
    //     /^[-*].\s/,
    //     /^\d+.\s/,
    //     /^##.\s/,
    //     /^#.\s.*=.*$/,
    //     /^#ALERT.\s/,
    //   ];
    //   monaco.languages.register({ id: "psmdoc" });
    //   monaco.languages.setMonarchTokensProvider("psmdoc", {
    //     defaultToken: "source",
    //     tokenizer: {
    //       root: [
    //         // Each block is separated by a blank line
    //         // Blocks can be of different types
    //         // Lines that start with # are typed content blocks
    //         // example
    //         //
    //         // #H1 My Title
    //         //
    //         // This is a paragraph
    //         //
    //         // #TABLE My Table
    //         // | Header 1 | Header 2 |
    //         // |----------|----------|
    //         // | Row 1    | Row 2    |
    //         //
    //         // #EL https://example.com   "Example Link"
    //         //
    //         // #CODE python
    //         // def hello():
    //         //     print("Hello World")
    //         // #ENDCODE
    //         //
    //         // #VIDEO https://www.youtube.com/watch?v=video-id width=560 height=315
    //         //
    //         // #IMG https://example.com/image.jpg "Image Title" width=560 height=315 fit=cover
    //         //
    //         // - Lists are also supported
    //         // - Lists can be ordered or unordered
    //         // - Lists can be nested
    //         //   - Nested list item
    //         //   - Nested list item
    //         // - Lists can have different types
    //         //   1. Ordered list item
    //         //   2. Ordered list item
    //         //   3. Ordered list item
    //         //
    //         // This a *bold* text and this is a _italic_ text and this is a ~strike~ text and combined *_bold italic_*
    //         //
    //         // [/^##\s.*$/, "constant"],
    //         // [/^#H([1-6])\s.*$/, "keyword"],
    //         // [/^#TABLE\s.*$/, "keyword"],
    //         // [/^#ACCORDION\s.*$/, ""],
    //         // [/^#EL\s.*$/, "keyword"],
    //         // [/^#CODE\s.*$/, "keyword"],
    //         // [/^#ENDCODE$/, "keyword"],
    //         // [/^#VIDEO\s.*$/, "keyword"],
    //         // [/^#IMG\s.*$/, "keyword"],
    //         // [/^#.*$/, "keyword"],
    //         // [/^[-*]\s.*$/, "keyword"],
    //         // [/^\d+\.\s.*$/, "keyword"],
    //         // key=value pairs should be highlighted

    //         // [
    //         //   /(^#.+<=\s|$)(.+[$=]?)\s?(\s[a-z]+=.+)/,
    //         //   ["keyword", "comment", "constant"],
    //         // ],
    //         [/(^#H[1-6]\s)(.+[\s]?)/, ["keyword", "string"]],
    //         [
    //           /(^#.+[\s]?)(.+[\s]?)(["'].+['"])(\s\w+=\w+[\s$]?)$/,
    //           ["keyword", "string", "comment", "constant"],
    //         ],
    //         [/(^#[E|I]L[\s]?)(.+[\s]?)$/, ["keyword", "string"]],
    //         [/\s(\w+=\w+)/, "constant"],
    //       ],
    //     },
    //   });
    //   const model = monaco.editor.createModel("", "psmdoc");
    //   monaco.editor.setModelLanguage(model, "psmdoc");
    //   monaco.editor.setTheme("psmdoc");
    // };

    // if (monaco) {
    //   addPsmDocLanguageToMonaco();
    // }

    return () => {};
  }, []);

  const renderFilesMenu = () => {
    return (
      <div className="mb-2 flex ">
        <div className="flex gap-1">
          <Tooltip content={t("newFolder")}>
            <Button
              onClick={() => {
                setDirListState({
                  ...initialStates,
                  ...dirListState,
                  newFolder: true,
                  currentDialog: "newFolder",
                });
              }}
              gradientMonochrome={"info"}
              size={"sm"}
              type="button"
            >
              <HiFolderAdd />
            </Button>
          </Tooltip>

          <Tooltip content={t("newFile")}>
            <Button
              onClick={() => {
                setDirListState({
                  ...initialStates,
                  ...dirListState,
                  newFile: true,
                  currentDialog: "newFile",
                });
              }}
              size={"sm"}
              gradientMonochrome={"success"}
              type="button"
            >
              <HiDocumentAdd />
            </Button>
          </Tooltip>
        </div>
        <div>
          <Tooltip content={t("syncFiles")}>
            <Button
              onClick={() => {
                setDirListState({
                  ...initialStates,
                  ...dirListState,
                  syncFiles: true,
                  currentDialog: "syncFiles",
                });
              }}
              size={"sm"}
              gradientMonochrome={"purple"}
              type="button"
            >
              <HiRefresh />
            </Button>
          </Tooltip>
        </div>
      </div>
    );
  };

  return (
    <aside
      id="dir-list"
      className={`${!hasParent ? "overflow-x-auto" : ""} h-full w-full overflow-y-auto bg-gray-100 p-2 dark:bg-gray-800`}
    >
      {!hasParent && (
        <div className="flex items-center gap-2">
          <Dialogs
            dialogs={[
              {
                id: "uploadFile",
                title: (
                  <div className="flex items-center gap-2">
                    <HiDocumentAdd />
                    {t("uploadFile")}
                  </div>
                ),
                body: <div>Content</div>,
              },
              {
                id: "newFolder",
                title: (
                  <div className="flex items-center gap-2">
                    <HiFolderAdd />
                    {t("newFolder")}
                  </div>
                ),
                body: <div>Content</div>,
              },
              {
                id: "newFile",
                title: (
                  <div className="flex items-center gap-2">
                    <HiDocumentAdd />
                    {t("newFile")}
                  </div>
                ),
                body: (
                  <div className=" container flex flex-col gap-2">
                    <form
                      id="newFileForm"
                      className="flex flex-col gap-2"
                      onSubmit={async (e) => {
                        e.preventDefault();
                        var fileName = (
                          document.getElementById(
                            "fileName",
                          ) as HTMLInputElement
                        ).value;
                        var fileType = (
                          document.getElementById(
                            "fileType",
                          ) as HTMLSelectElement
                        ).value;
                        var path = "";
                        if (fileName.includes("/")) {
                          path = fileName.substring(
                            0,
                            fileName.lastIndexOf("/"),
                          );
                          fileName = fileName.substring(
                            fileName.lastIndexOf("/") + 1,
                          );
                        }
                        if (fileType !== "") {
                          if (!fileName.endsWith(`.${fileType}`)) {
                            fileName += `.${fileType}`;
                          }
                        }
                        var newFile = await addProjectFile({
                          id: 0,
                          name: fileName,
                          path: path,
                          kind: "file",
                        });
                        if (newFile) {
                          var d = {} as Record<string, any>;
                          Object.keys(newFile).forEach((key: string) => {
                            // Change the parameter type to string
                            if (key !== "entry" && key !== "blob") {
                              // Remove unnecessary type assertion
                              d[key] = newFile[key as keyof FileItem];
                            }
                          });
                          if (
                            !openFiles.find(
                              (f: FileItem) => f.id === newFile.id,
                            )
                          ) {
                            dispatch(setOpenFiles([...openFiles, d]));
                          }
                          dispatch(setCurrentFile(d));
                        }
                        setDirListState({
                          ...dirListState,
                          ...initialStates,
                          currentDialog: "",
                        });
                      }}
                    >
                      <div className="flex flex-col gap-2">
                        <Label>{t("fileName")}</Label>
                        <TextInput
                          id="fileName"
                          placeholder={t("fileName")}
                          type="text"
                        />
                      </div>
                      <div className="flex flex-col gap-2">
                        <Label>{t("fileType")}</Label>
                        <Select id="fileType" defaultValue="psmdoc">
                          <option value="psmdoc">PsmDoc</option>
                          <option value="txt">Text</option>
                          <option value="json">JSON</option>
                          <option value="">Plain Text</option>
                        </Select>
                      </div>
                      <Button
                        type="submit"
                        gradientMonochrome={"success"}
                        size="md"
                        className="flex w-full items-center justify-center gap-2 text-center align-middle"
                      >
                        <HiDocumentAdd />
                        {t("createFile")}
                      </Button>
                    </form>
                  </div>
                ),
              },
              {
                id: "syncFiles",
                title: (
                  <div className="flex items-center gap-2">
                    <HiRefresh />
                    {t("syncFiles")}
                  </div>
                ),
                body: <div>Content</div>,
              },
            ]}
            currentDialog={dirListState.currentDialog}
            onClose={(id: string) => {
              setDirListState({
                ...dirListState,
                ...initialStates,
                currentDialog: "",
              });
            }}
          />
          {/* add nav icons menu to new folder and file */}
          {/* /* use flowbite framework  */}
          {renderFilesMenu()}
        </div>
      )}{" "}
      {/* end of hasParent */}
      {/* add border to left */}
      <ul
        className={`${hasParent ? "gap-1 border-l border-gray-200 pl-1" : ""} overflow-x-hidden p-0`}
      >
        {dirs?.map((dir, index) => {
          var liClass =
            "text-nowrap text-sm dark:text-gray-300 cursor-pointer ";
          if (dir.kind === "directory") {
            liClass += "ml-1 text-blue-500 text-sm dark:text-blue-300";
          } else {
            liClass = "ml-1 text-green-500 text-sm";
          }
          if (dir.kind === "file") {
            liClass = "ml-1 text-gray-500 text-sm cursor-pointer";
            if (dir.name.endsWith(".psmdoc")) {
              liClass += " dark:text-green-500 text-green-500";
            }
          }

          return (
            <li
              key={index}
              className={`${liClass}`}
              onContextMenu={(e) => {
                e.preventDefault();
                console.log("Right click");
              }}
            >
              <span
                className="flex items-center gap-2"
                onClick={() => {
                  if (
                    dir.children &&
                    dir.children.length > 0 &&
                    dir.kind === "directory"
                  ) {
                    setDirListState({
                      ...dirListState,
                      expandedFolders: dirListState.expandedFolders.includes(
                        dir.name,
                      )
                        ? dirListState.expandedFolders.filter(
                            (folder) => folder !== dir.name,
                          )
                        : [...dirListState.expandedFolders, dir.name],
                    });
                  } else {
                    if (dir.kind === "file") {
                      var d = {} as Record<string, any>;
                      Object.keys(dir).forEach((key: string) => {
                        // Change the parameter type to string
                        if (key !== "entry" && key !== "blob") {
                          // Remove unnecessary type assertion
                          d[key] = dir[key as keyof FileItem];
                        }
                      });
                      if (!openFiles.find((f: FileItem) => f.id === dir.id)) {
                        dispatch(setOpenFiles([...openFiles, d]));
                      }
                      dispatch(setCurrentFile(d));
                    }
                  }
                }}
              >
                {dir.kind === "directory" && (
                  <span className="mr-2 ">
                    {dirListState.expandedFolders.includes(dir.name) ? (
                      <span>
                        <HiChevronDown />
                      </span>
                    ) : (
                      <span>
                        <HiChevronRight />
                      </span>
                    )}
                  </span>
                )}

                {dir.name}
              </span>
              {dir.children &&
                dirListState.expandedFolders.includes(dir.name) && (
                  <DirList dirs={dir.children} hasParent={true} />
                )}
            </li>
          );
        })}
      </ul>
    </aside>
  );
};

export default DirList;

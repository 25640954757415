import React from "react";
import { useTranslation } from "react-i18next";
const NotFoundError = ({ message }: { message?: string }) => {
  const { t } = useTranslation();
  // use flowbite components
  return (
    <div className="flex h-screen items-center justify-center">
      <div className="text-center">
        <h1 className="text-6xl font-bold text-gray-900">404</h1>
        <h2 className="text-2xl font-semibold text-gray-600">Page not found</h2>
        <p className="mt-2 text-lg text-gray-500">
          {message ||
            t(
              "Wait, where things go?  Sorry, we couldn't find the page you were looking for.",
            )}
        </p>
      </div>
    </div>
  );
};

export default NotFoundError;

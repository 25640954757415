import { Avatar, DarkThemeToggle, Dropdown, Navbar } from "flowbite-react";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import {
  HiCog,
  HiCurrencyDollar,
  HiLogout,
  HiViewGrid,
  HiUser,
  HiQuestionMarkCircle,
} from "react-icons/hi";
import { appName } from "../../config";
import useAuth from "../../hooks/useAuth";
const DashBoardHeader = () => {
  const { t } = useTranslation();
  const { currentProject, projectWsOpen } = useSelector((state: any) => state.app);
  const { user, logout } = useAuth();

  const links = [
    { name: t("Home"), href: "/" },
    { name: t("Projects"), href: "/projects" },
  ];

  return (
    <Navbar fluid rounded>
      <Navbar.Brand href="/">
        <img
          src="https://flowbite.com/docs/images/logo.svg"
          className="mr-3 h-6 sm:h-9"
          alt="PSMDOC Logo"
        />
        <span className="self-center whitespace-nowrap text-xl font-semibold dark:text-white">
          {appName}
        </span>
      </Navbar.Brand>
      <DarkThemeToggle />
      {currentProject && (
        <span className="text-sm text-gray-500 dark:text-gray-400">
          {projectWsOpen ? "🟢" : "🔴"}
          {currentProject.name}
        </span>
      )}
      <div className="flex md:order-2">
        <Dropdown
          arrowIcon={false}
          inline
          label={
            <Avatar
              alt="User settings"
              img="https://flowbite.com/docs/images/people/profile-picture-5.jpg"
              rounded
            />
          }
        >
          <Dropdown.Header>
            <span className="block text-sm">{user?.first_name}</span>
            <span className="block truncate text-sm font-medium">
              {user?.email || t("user")}
            </span>
          </Dropdown.Header>
          <Dropdown.Item icon={HiUser} href="/profile">
            {t("Profile")}
          </Dropdown.Item>
          <Dropdown.Item href="/projects" icon={HiViewGrid}>
            {t("Projects")}
          </Dropdown.Item>
          <Dropdown.Item icon={HiCog}>{t("Settings")}</Dropdown.Item>
          <Dropdown.Item icon={HiQuestionMarkCircle} href="/help">
            {t("Help")}
          </Dropdown.Item>
          <Dropdown.Item icon={HiCurrencyDollar}>{t("Billing")}</Dropdown.Item>
          <Dropdown.Divider />
          <Dropdown.Item
            icon={HiLogout}
            onClick={() => {
              logout();
            }}
          >
            {t("Logout")}
          </Dropdown.Item>
        </Dropdown>
        <Navbar.Toggle />
      </div>
      <Navbar.Collapse>
        <Navbar.Link href="#" active>
          {t("Home")}
        </Navbar.Link>
        <Navbar.Link href="/">Projects</Navbar.Link>
      </Navbar.Collapse>
    </Navbar>
  );

  return (
    <header>
      <div className="mx-auto flex max-w-screen-xl flex-wrap items-center justify-between">
        <a href="https://flowbite.com" className="flex items-center">
          <img
            src="https://flowbite.com/docs/images/logo.svg"
            className="mr-3 h-6 sm:h-9"
            alt="PSMDOC Logo"
          />
          <span className="self-center whitespace-nowrap text-xl font-semibold dark:text-white">
            PSMDOC
          </span>
        </a>
        <div className="flex items-center lg:order-2">
          <nav className="hidden lg:flex">
            <ul className="flex space-x-6">
              {links.map((link, index) => (
                <li key={index}>
                  <a
                    href={link.href}
                    className="text-gray-700 hover:text-gray-900 dark:text-gray-400 dark:hover:text-gray-300"
                  >
                    {link.name}
                  </a>
                </li>
              ))}
            </ul>
          </nav>
          <DarkThemeToggle />
        </div>
        {/* add mobile menu */}
        <div className="lg:hidden">
          <button
            className="flex items-center justify-center rounded-md p-2 text-gray-700 dark:text-gray-400"
            aria-label="Toggle menu"
          >
            <svg
              className="size-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M4 6h16M4 12h16m-7 6h7"
              ></path>
            </svg>
          </button>
          <nav className="hidden">
            <ul>
              {links.map((link, index) => (
                <li key={index}>
                  <a
                    href={link.href}
                    className="block py-2 text-gray-700 hover:text-gray-900 dark:text-gray-400 dark:hover:text-gray-300"
                  >
                    {link.name}
                  </a>
                </li>
              ))}
            </ul>
          </nav>
          <DarkThemeToggle />
        </div>
      </div>
    </header>
  );
};

export default DashBoardHeader;

export interface Project {
  id: number;
  name: string;
  description: string | null;
  enabled: boolean;
  files?: FileItem[];
  projectKey?: string;
}

export class ConvertProject {
  static fromJson(json: any): Project {
    return {
      id: json.id,
      name: json.name,
      description: json.description,
      enabled: json.enabled,
      projectKey: json.project_key,
      files: json.files,
    };
  }
}

export interface FileItem {
  id: number;
  name: string;
  kind: string;
  path?: string;
  children?: FileItem[];
  entry?: FileSystemFileHandle;
  blob?: Blob;
}

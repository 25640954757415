import React, { useEffect } from 'react';
import useAuth from '../../hooks/useAuth';
import LoadingScreen from '../../components/LoadingScreen';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

const Logout = () => {
    const { logout, isAuthenticated} = useAuth();
    const {t} = useTranslation();
    const navigate = useNavigate();
    useEffect(() => {
        logout();
    }, []);

    useEffect(() => {
        if (!isAuthenticated) {
            navigate('/login');
        }
    }, [isAuthenticated]);

    return <LoadingScreen message={t('loggingOut')} />;
}

export default Logout;
import axios from "../utils/axiosInstance";
import { localStorageVars, server } from "../config";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setAppStateItems } from "../slices/appSlice";



const useAuth = () => {
  const {
    isAuthenticated,
    user,
    authLoaded,
    isLoadingProfile,
    isAuthenticating,
  } = useSelector((state: any) => state.app);
  const dispatch = useDispatch();
  const initialized = useRef(false);
  const login = async (email: string) => {
    var res = await axios.post(`${server}/api/login/`, { email });
    return res.data;
  };

  const authenticate = async (email: string, code: number) => {
    if (!email || !code) {
      return { error: "Email and code are required" };
    }
    if (isAuthenticating) {
      return { error: "Already authenticating" };
    }
    dispatch(setAppStateItems({ isAuthenticating: true }));
    try {
      var res = await axios.post(`${server}/api/authenticate/`, {
        email,
        code,
        client_id: "5HhA4uotf1ypD4RBwnmET1P2Reby6Fb4hPoSWKPD",
        client_secret:
          "pbkdf2_sha256$720000$MBzDJYWUuLGejhl1orfQbl$R21WEXqNTOsCypLbBHZ1biqMkTUajNu/ui6urTpwVdA=",
      });

      // save to local storage
      localStorage.setItem(localStorageVars.token, res.data.data.access_token);
      return res.data;
    } catch (err) {
      console.error(err);
      dispatch(
        setAppStateItems({
          isAuthenticated: false,
          user: null,
          authLoaded: true,
          isLoadingProfile: false,
          isAuthenticating: false,
        }),
      );

      return { error: err };
    }
  };

  const getProfile = async () => {
    
    if (isLoadingProfile) {
      return;
    }
    dispatch(setAppStateItems({ isLoadingProfile: true }));

    const accessToken = localStorage.getItem(localStorageVars.token);
    console.log(`accessToken: ${accessToken}`);
    if (!accessToken) {
      dispatch(
        setAppStateItems({
          isAuthenticated: false,
          user: null,
          authLoaded: true,
          isLoadingProfile: false,
          isAuthenticating: false,
        }),
      );

      return;
    }

    dispatch(setAppStateItems({ isLoadingProfile: true }));

    try {
      var res = await axios.post(
        `${server}/api/getProfile/`,
        {
          fields: ["id", "first_name", "last_name", "is_active"],
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        },
      );

      dispatch(
        setAppStateItems({
          isAuthenticated: true,
          user: res.data.data,
          authLoaded: true,
          isLoadingProfile: false,
          isAuthenticating: false,
        }),
      );

      return res.data;
    } catch (err) {
      console.error(err);
      dispatch(
        setAppStateItems({
          isAuthenticated: false,
          user: null,
          authLoaded: true,
          isLoadingProfile: false,
          isAuthenticating: false,
        }),
      );

      return { error: err };
    }
  };

  useEffect(() => {
    if(!initialized.current ) {
      initialized.current = true;
      
    }
  }, []);

  const logout = () => {
    localStorage.removeItem(localStorageVars.token);
    dispatch(
      setAppStateItems({
        isAuthenticated: false,
        user: null,
        authLoaded: true,
        isLoadingProfile: false,
        isAuthenticating: false,
      }),
    );
  };

  return {
    login,
    authenticate,
    getProfile,
    logout,
    isAuthenticated,
    user,
    authLoaded,
    isLoadingProfile,
    isAuthenticating,
  };
};

export default useAuth;

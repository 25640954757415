import { createSlice } from "@reduxjs/toolkit";
import { FileItem, Project } from "../types/projectTypes";
import { loadAppStorage, saveAppStorage } from "../storage/appStorage";
export const appSlice = createSlice({
  name: "app",
  initialState: {
    mainFolder: null as string | null,
    expandedFolders: [] as string[],
    openFiles: [] as FileItem[],
    files: [] as FileItem[],
    filesContent: {} as { [key: string]: {
      origin: string;
      content: string;
    } },
    currentFile: null as FileItem | null,
    projects: [] as Project[],
    currentProject: null as Project | null,
    user: null as any,
    isAuthenticated: false,
    authLoaded: false,
    isAuthLoading: false,
    isAuthenticating: false,
    projectWsOpen: false,
  },
  reducers: {
    setExpandedFolders: (state, action) => {
      state.expandedFolders = action.payload;
    },
    setOpenFiles: (state, action) => {
      state.openFiles = action.payload;
    },
    closeFile: (state, action) => {
      if (state.filesContent[action.payload]) {
        delete state.filesContent[action.payload];
        if (state.currentFile?.id === action.payload) {
          state.currentFile = null;
        }
      }
      state.openFiles = state.openFiles.filter((f) => f.id !== action.payload);
    },
    setFiles: (state, action) => {
      state.files = action.payload;
    },
    setCurrentFile: (state, action) => {
      if (!action.payload && state.currentFile) {
        // remove from open files if it is already open

        state.openFiles = state.openFiles.filter(
          (f) => f.id !== state.currentFile?.id,
        );
        // remove from filesContent if it is already open
        delete state.filesContent[state.currentFile.id];
      }

      state.currentFile = action.payload;
    },
    setMainFolder: (state, action) => {
      state.mainFolder = action.payload;
    },
    setProjects: (state, action) => {
      state.projects = action.payload;
    },
    setCurrentProject: (state, action) => {
      state.currentProject = action.payload;
    },
    setFilesContent: (state, action) => {
      state.filesContent = action.payload as { [key: string]: {
        origin: string;
        content: string;
      } } | {};
    },
    restoreState: (state, action) => {
      state = action.payload;
    },
    setAppStateItems: (state: { [key: string]: any }, action) => {
      Object.keys(action.payload).forEach((key: string) => {
        state[key] = action.payload[key];
      });
    }
  },
});

export const {
  setExpandedFolders,
  setOpenFiles,
  setFiles,
  setCurrentFile,
  setMainFolder,
  setCurrentProject,
  setFilesContent,
  closeFile,
  setAppStateItems,
} = appSlice.actions;

export const setProjects =
  (projects: any[]): any =>
  async (dispatch: any) => {
    dispatch(appSlice.actions.setProjects(projects));
    saveAppStorage(appSlice);
  };

export const appStateFromStorage = async () => {
  const savedState = await loadAppStorage();
  appSlice.actions.restoreState(savedState);
};


import React, { useEffect, useState } from "react";
// import monaco-editor

import Editor, { useMonaco } from "@monaco-editor/react";
import { useThemeMode } from "flowbite-react";
import { FileItem } from "../types/projectTypes";

// .psmdoc file editor
// we are using https://github.com/esbozos/psmdoc specification for editing
// PSMDOC files
// editor receives a file path and loads the file content
// editor allows to edit the file content
// editor allows to save the file content
// editor allows to preview the file content
// editor allows to export the file content
// editor allows to import the file content
// editor allows to create a new file
// editor allows to delete the file
// editor allows to rename the file
// editor allows to move the file
// editor allows to copy the file
// editor allows to share the file
// editor allows to search the file content
// editor allows to replace the file content
// editor allows to undo the changes
// editor allows to redo the changes
// editor allows to change the editor theme
// editor allows to change the editor font
// editor allows to change the editor font size
// editor allows to change the editor language
// editor allows to change the editor line numbers
// editor allows to change the editor word wrap
// editor allows to change the editor indentation
// editor allows to change the editor tab size
// editor allows to change the editor key bindings
// editor allows to change the editor auto save
// editor allows to change the editor auto save delay
// show white space




const PSMDocEditor = ({
  file,
  content,
  onChange,
}: {
  content: string;
  onChange: (value: string | undefined, event: any) => void;
  file: FileItem;
}) => {
  // get theme from flowbite
  const { mode } = useThemeMode();
  const monaco = useMonaco();

  useEffect(() => {
    if (!monaco) return;
    try {
      // add PSMDOC language to monaco

      monaco.languages.register({ id: "psmdoc" });
      monaco.languages.setMonarchTokensProvider("psmdoc", {
        defaultToken: "source",
        tokenizer: {
          root: [
            // Each block is separated by a blank line
            // Blocks can be of different types
            // Lines that start with # are typed content blocks
            // example
            //
            // #H1 My Title
            //
            // This is a paragraph
            //
            // #TABLE My Table
            // | Header 1 | Header 2 |
            // |----------|----------|
            // | Row 1    | Row 2    |
            // #ENDTABLE
            // #ACCORDION My Accordion
            // #EL My Element
            // #CODE My Code
            // #ENDCODE
            // #VIDEO My Video
            // #IMG My Image
            // #ALERT My Alert
            // #ENDALERT
            // #ENDACCORDION
            [/(^#H[1-6]\s)(.+[\s]?)/, ["keyword", "string"]],
            [
              /(^#.+[\s]?)(.+[\s]?)(["'].+['"])(\s\w+=\w+[\s$]?)$/,
              ["keyword", "string", "comment", "constant"],
            ],
            [/^(#[E|I]L[\s]?)(.+[\s]?)$/, ["keyword", "string"]],
            [/\s(\w+=\w+)/, "constant"],
            //  table Caption
            [/(^#TABLE)(.+[\s]?)$/, ["keyword", "string"]],
            // table headers
            [/\|(.+)\|/, "string"],
            // VIDEO
            [/(^#VIDEO)(.+[\s]?)$/, ["keyword", "string"]],
            // IMG
            [/(^#IMG)(.+[\s]?)$/, ["keyword", "string"]],
            // ALERT
            [/(^#ALERT)(.+[\s]?)$/, ["keyword", "string"]],
            [/(^#ENDALERT)(.+[\s]?)$/, ["keyword", "string"]],
            // CODE
            [/(^#CODE)(.+[\s]?)$/, ["keyword", "string"]],
            [/(^#ENDCODE)(.+[\s]?)$/, ["keyword", "string"]],
            // ACCORDION
            [/(^#ACCORDION)(.+[\s]?)$/, ["keyword", "string"]],
            [/(^#ENDACCORDION)(.+[\s]?)$/, ["keyword", "string"]],
            // *bold*
            [/\*([^*]+)\*/, "boldtext"],
            // _italic_
            [/_([^_]+)_/, "italictext"],
          ],
        },

        rules: [
          { token: "source", foreground: "000000" },
          { token: "keyword", foreground: "0000FF" },
          { token: "string", foreground: "008000" },
          { token: "comment", foreground: "808080" },
          { token: "constant", foreground: "FF0000" },
          { token: "boldtext", fontStyle: "bold", foreground: "033000" },
          { token: "italictext", fontStyle: "italic" },
        ],
      });
      monaco.languages.setLanguageConfiguration("psmdoc", {});

      const model = monaco.editor.createModel("", "psmdoc");
      monaco.editor.setModelLanguage(model, "psmdoc");
      monaco.editor.setTheme("psmdoc");
    } catch (e) {
      console.error(e);
    }
  }, [monaco]);
  const guessLanguage = () => {
    // guess the language from the file extension
    const ext = file.name.split(".").pop();
    switch (ext) {
      case "js":
        return "javascript";
      case "ts":
        return "typescript";
      case "json":
        return "json";
      case "xml":
        return "xml";
      case "html":
        return "html";
      case "css":
        return "css";
      case "md":
        return "markdown";
      case "psmdoc":
        return "psmdoc";
      default:
        return "plaintext";
    }
  };
  return (
    <div className="flex  w-full flex-col">
      {/* <PSMDocToolbar /> */}
      {/* <textarea
        className="h-screen w-full p-2"
        value={content}
        onChange={(e) => onChange(e.target.value)}
      /> */}
      {/* use a div with contenteditable=true to allow editing */}
      {/* show lines numbers */}
      <div className="flex">
        <Editor
          height={"89vh"}
          defaultLanguage="psmdoc"
          value={content}
          language={guessLanguage() as string | undefined}
          onChange={onChange}
          options={{
            wordWrap: "on",
            renderWhitespace: "all",
          }}
          // eslint-disable-next-line tailwindcss/no-custom-classname
          theme={mode === "dark" ? "vs-dark" : "vs-light"}
        />
        {/* <div
          className="h-screen w-full overflow-y-scroll p-2"
          dangerouslySetInnerHTML={editorContent}
          contentEditable={true}
          onInput={(e) => {
            // update the content
            onChange(e.currentTarget.innerHTML);
          }}
        ></div> */}
      </div>
    </div>
  );
};
export default PSMDocEditor;

import {
  Button,
  ButtonGroup,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "flowbite-react";
import React from "react";

const Dialog = ({
  title,
  children,
  onClose,
  actions,
  open,
  size,
}: {
  title: any;
  children: any;
  onClose: any;
  actions: any;
  open: boolean;
  size?: string;

}) => {
  // use tailwind css classes to style the dialog
  return (
    <Modal show={open} onClose={onClose} size={size} popup>
      <ModalHeader>{title}</ModalHeader>
      <ModalBody>{children}</ModalBody>
      {actions && actions.length > 0 && (
        <ModalFooter>
          <Button.Group color="info">
            {actions.map((action: any, index: number) => (
              <Button key={index} onClick={action.onClick}>
                {action.label}
              </Button>
            ))}
            <Button onClick={onClose} color="secondary">
              Cancel
            </Button>
          </Button.Group>
        </ModalFooter>
      )}
    </Modal>
  );
};

interface DialogProps {
  title: any;
  body: any;  
  actions?: any;
  id: string;
  size?: string;
}

const Dialogs = ({
  currentDialog,
  onClose,
  dialogs = [],
}: {
  dialogs: DialogProps[];
  currentDialog: string | null;
  onClose: any;
}) => {
  return (
    // add a wrapper to show all dialogs
    // use tailwind css classes to style the wrapper
    
    <div className="hidden">
      {dialogs &&
        dialogs.length > 0 &&
        dialogs.map((dialog: DialogProps, index: number) => {
          if (dialog.id === currentDialog) {
            return (
              <Dialog
                key={index}
                title={dialog.title}
                onClose={onClose}
                actions={dialog.actions}
                open={true}
                size={dialog.size}
              >
                {dialog.body}
              </Dialog>
            );
          }
        })}
    </div>
  );
};

export { Dialogs, Dialog };

import localforage from "localforage";
import { localStorageVars } from "../config";

const indexedDBSupported = localforage.supports(localforage.INDEXEDDB);
if (!indexedDBSupported) {
  console.log("IndexedDB is not supported");
  localforage.setDriver(localforage.LOCALSTORAGE);
} else {
  console.log("IndexedDB is supported");
  localforage.setDriver(localforage.INDEXEDDB);
}
const appStorage = localforage.createInstance({
  name: localStorageVars.storageName,
  storeName: "app",
});

const loadAppStorage = async () => {
  try {
    const serializedState = await appStorage.getItem("state");
    if (serializedState === null) {
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch {
    return undefined;
  }
};

const saveAppStorage = async (state) => {
  console.log("Saving app storage");
  try {
    const serializedState = JSON.stringify(state);
    await appStorage.setItem("state", serializedState);
  } catch {
    console.log("Error saving app storage");
  }
};

export { loadAppStorage, saveAppStorage };
